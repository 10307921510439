import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_ROOT_API;

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }
  getUserBoard() {
    return axios.get(API_URL + '/usuario/usuario/bytoken', { headers: authHeader() });
  }
  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }
  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
  getData() {
    return axios.get(API_URL + '/usuario/usuario/bytoken', { headers: authHeader() });
  }
}
export default new UserService();