<template>
  <div class="container">



<hr />

<div v-if="this.content && this.content.groups.id == 1" >

    <AdminDashboard :opGroup="this.content.groups.id"></AdminDashboard>

</div>

<div v-else-if="this.content && this.content.groups.id == 3">

  <CalidadDashboard :opGroup="this.content.groups.id"></CalidadDashboard>

</div>
<div v-else-if="this.content" >

 <OperatorDashboard :opId="this.content.id" :opGroup="this.content.groups.id"></OperatorDashboard>  

</div>

    <!---

	  {{ content }}

    --->

    <!-- <div class="col-12 section_title"> INICIO ({{ this.group }})</div> -->

    <!--- admin panel --->

    <!-- <div class="">
      <AdminDashboard></AdminDashboard>
    </div> -->

    <!--- operator panel 
			<hr>

		<div class="">

			<OperatorDashboard></OperatorDashboard>

		</div>--->
  </div>
</template>

<script>
import EventBus from "@/AppEventBus";
import AdminDashboard from "./dashboards/AdminDashboard.vue";
import CalidadDashboard from "./dashboards/CalidadDashboard.vue";
import OperatorDashboard from "./dashboards/OperatorDashboard.vue";
import UserService from "../services/user.service";

export default {
  components: {
    AdminDashboard,
    OperatorDashboard,
    CalidadDashboard
  },
  data() {
    return {
      group: "",
      content: null,
      lineData: {
        labels: ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL"],
        datasets: [
          {
            label: "Dato 1",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: "#2f4860",
            borderColor: "#2f4860",
            tension: 0.4,
          },
          {
            label: "Dato 2",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: "#00bb7e",
            borderColor: "#00bb7e",
            tension: 0.4,
          },
        ],
      },
      items: [
        { label: "Add New", icon: "pi pi-fw pi-plus" },
        { label: "Remove", icon: "pi pi-fw pi-minus" },
      ],
      lineOptions: null,
    };
  },
  productService: null,
  themeChangeListener: null,
  mounted() {


    UserService.getUserBoard().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );

    this.themeChangeListener = (event) => {
      if (event.dark) this.applyDarkTheme();
      else this.applyLightTheme();
    };
    EventBus.on("change-theme", this.themeChangeListener);

    if (this.isDarkTheme()) {
      this.applyDarkTheme();
    } else {
      this.applyLightTheme();
    }
  },
  beforeUnmount() {
    EventBus.off("change-theme", this.themeChangeListener);
  },
  created() {},
  methods: {
    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    isDarkTheme() {
      return this.$appState.darkTheme === true;
    },
    applyLightTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      };
    },
    applyDarkTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: "#ebedef",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#ebedef",
            },
            grid: {
              color: "rgba(160, 167, 181, .3)",
            },
          },
          y: {
            ticks: {
              color: "#ebedef",
            },
            grid: {
              color: "rgba(160, 167, 181, .3)",
            },
          },
        },
      };
    },
  },
};
</script>