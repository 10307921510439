<template>
    <div class="grid">
        <Dialog header="Seleccione el operador" v-model:visible="showOperatorModal" :style="{width: '50vw'}">

            <Button 
                v-for="(operator, index) in operators"
                :key="operator.id"
                :label="operator.name"
                :class="selectedOperator == operator.id ? ' p-button-success col-3 mx-1 my-1 text-lg' : 'p-button-secondary col-3 mx-1 my-1 text-lg'" 
                :icon="selectedOperator == operator.id ? 'pi pi-check' : 'pi pi-circle-on'"
                @click="selectedOperator = operator.id">
            </Button>

            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" @click="closeBasic" class="p-button-text"/>
                <Button label="Confirmar" icon="pi pi-check" @click="setOperator()" autofocus />
            </template>
        </Dialog>


        <Dialog header="Motivo de la pausa?" v-model:visible="showPauseMotiveModal" :style="{width: '50vw'}">
            <div  class="grid" >
                <div v-for="motive in pauseOptions" class="col-6">
                    <Button  :label="motive.name" class="motiveButton w-full" @click="setPause(motive.id)" />
                </div>
            </div>
        </Dialog>



    <div class="col-12 pb_running" v-if="order.status == 1 || order.status == 7">
        <ProgressBar :value="Math.trunc(getPercent(order.time))" style="height: 1.5em" />
    </div>
    <div  class="col-6" v-if="order.status == 0 && order.current_operator">
        <div :class="'oti_action st_bg_color_1'" @click="statusTo(1)">
            <div class="text-center">
                <div class="">
                    <i class="pi pi-play" ></i>
                    <span> INICIAR </span>
                </div>
                <div class="ml-auto p-2"  style=""><span> </span></div>
            </div>
        </div>
    </div>
    <div  class="col-12" v-if="order.status == 0 && !order.current_operator"  @click="statusTo(0)">
        <div :class="'oti_action st_bg_color_0'" >
            <div class="text-center">
                <div class="">
                    <i class="pi pi-user" ></i>
                    <span> ASIGNAR</span>
                </div>
            </div>
        </div>
    </div>
    <div  class="col-12" v-if="(order.status == 0 || order.current_operator) && opGroup == 1 "  @click="statusTo(0)">
        <div :class="'oti_action st_bg_color_0'" >
            <div class="text-center">
                <div class="">
                    <div class=" ">
                    <i class="pi pi-user" ></i>
                    <span> REASIGNAR
                    </span>
                    </div>

                </div>
                
                <div class="ml-auto p-2"  style=""><span> </span></div>
            </div>
        </div>
    </div>

    <div  class="col-6" v-if="order.status == 2" @click="statusTo(1)">
        <div :class="'oti_action st_bg_color_1'">
            <div class="text-center">
                <div class="">
                    <i class="pi pi-play" ></i>
                    <span> REANUDAR </span>
                </div>
                <div class="ml-auto p-2"  style=""><span> </span></div>
            </div>
        </div>
    </div>
    <div  class="col-6" v-if="order.status == 1" @click="statusTo(2)">
        <div :class="'oti_action st_bg_color_2'">
            <div class="text-center">
                <div class="">
                    <i class="pi pi-pause" ></i>
                    <span> PAUSAR </span>
                </div>
                <div class="ml-auto p-2"  style=""><span> </span></div>
            </div>
        </div>
    </div>
    <div  class="col-6" v-if="order.status == 1 || status == 2">
        <div :class="'oti_action st_bg_color_3'" @click="statusTo(3)">
            <div class="text-center">
                <div class="">
                    <i class="pi pi-check" ></i>
                    <span> FINALIZAR </span>
                </div>
                <div class="ml-auto p-2"  style=""><span> </span></div>
            </div>
        </div>
    </div>


  </div>
</template>

<script>
import OtiService from "./../services/oti.service.js";
import OperatorService from "./../services/operator.service.js";

export default {

    props: {
        order: {
        },
        opGroup: {
        },
    },
    emits: ['logChange'],
    data() {
        return {
            pauseOptions: [
                {name: 'Falta material', id: 1},
                {name: 'Mantenimiento', id: 2},
                {name: 'Corte de luz', id: 3},
                {name: 'Almuerzo', id: 4},
                {name: 'Fin de turno', id: 5},
                {name: 'Otro', id: 6},

            ],
            userGroup: this.opGroup,
            actualStatus: this.order.status,
            showOperatorModal: false,
            showReportMotiveModal: false,
            showPauseMotiveModal: false,
            pauseMotive: '',
            selectedOperator: null,
            operators: [ ],
            fullPercent: 72000, // 5 min 
        }
    },
    created() {
        this.getOperators();
    },
    methods: {
        getPercent(time) {
            //console.log(time)
            if(time < 2000) {
                return 1;
            }
            return time / this.fullPercent * 100;
        },
        getOperators() {
            OperatorService.getOperators().then(response => {
                
                this.operators = response;
            });
        },
        closeBasic() {
            this.showOperatorModal = false;
        },
        closePauseModal() {
            this.showPauseMotiveModal = false;
        },
        setOperator() {
            this.showOperatorModal = false;
            this.order.current_operator = this.selectedOperator;
            this.order.status = 0;

            let data = {
                order_id: this.order.id,
                operator_id: this.selectedOperator,
                current_operator: this.selectedOperator,
                status: this.order.status,
                time: this.order.time,
            };
            
            
            OtiService.updateOti(data).then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
        }, 
        setPause(motive){

            this.order.status = 2;
            this.order.pause_motive = motive;
            let data = {
                order_id: this.order.id,
                operator_id: this.selectedOperator,
                status: this.order.status,
                time: this.order.time,
            };
            
            OtiService.updateOti(data).then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
            
            this.showPauseMotiveModal = false;


        },
        statusTo(val){

            if(val == 0){
                this.showOperatorModal = true
                return
            }

            if(val == 2){
                this.showPauseMotiveModal = true
                return
            }

            this.order.status = val
            
            let data = {
                order_id: this.order.id,
                operator_id: this.selectedOperator,
                status: this.order.status,
                time: this.order.time,
            };
            
            OtiService.updateOti(data).then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
            
            this.$emit('logChange', val)
        }, 
        init() {
            this.$emit('init')
        }, 
        finish() {
            this.$emit('finish')
        }
    },
    computed: {
        statusClass() {
            switch (this.actualStatus) {
                case 0:
                    return 'pi pi-exclamation-circle';
                case 1:
                    return 'pi pi-play';
                case 2:
                    return 'pi pi-pause';
                case 3:
                    return 'pi pi-star';
                default:
                    return 'pi pi-shopping-cart';
            }
        },
        statusName() {
            switch (this.actualStatus) {
                case 0:
                    return ' Sin Iniciar';
                case 1:
                    return ' En proceso';
                case 2:
                    return ' En pausa';
                case 3:
                    return ' En calidad';
                case 4:
                    return ' Aprobada';
                default:
                    return ' - ';

            }
        },
    },

}
</script>

<style lang="scss" scoped>
    .motiveButton {
        
        font-size: 1.8rem;
        text-transform: uppercase;
        margin: 1%;
        background: #ff99005b;
        color: black;
        border: 1px solid #ff99005b;
        &:hover {
            background: #ff9900;
            color: black;
            border: 1px solid #ff9900;
        }
    }

    .oti_action { 
        cursor: pointer;
        text-transform: uppercase;
        line-height: 75px;
        height: 75px;
        font-size: 19px;
        & >div i {
            font-size: 22px;
            line-height: 30px;
        }
        &.st_bg_color_3 {
            border: 1px solid #44b73a71;
            background-color: #44b73a71;
            & >div i {
                color: #44b73a;
            }
        }
        &.st_bg_color_2 {
            border: 1px solid #ff99005b;
            background-color: #ff99005b;
            & >div i {
                color: #FF9800;
            }
        }
        &.st_bg_color_1 {
            border: 1px solid #1c96c256;
            background-color: #1c96c256;
            & >div i {
                color: #0b3b5a;
            }
        }
        &.st_bg_color_0 {
            background-color: #2ce4d49a;
            & >div i {
                color: #000000;
            }
        }selectedOperator
        &.st_bg_color_4 {
            background-color: #d8d8d89a;
            & >div i {
                color: #807f7f;
            }
        }
    }

.p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: red;
}
</style>
