<template>
     <div class="col-12 card" >
        <Toast />

        <Dialog header="REPORTAR INCIDENCIA" v-model:visible="showReportMotiveModal" :style="{width: '50vw'}">
            <div  class="grid" >
                <div class="col-12">
                    <input type="text" v-model="motivo" class="p-text p-component w-full incidencia inputfield" placeholder="Motivo">
                </div>
                <div v-for="report in reportOptions" class="col-12">
                    <Button  :label="report.name" class="reportButton w-full" @click="setReport(report.id)" />
                </div>
            </div>
        </Dialog>

        <div class="flex justify-content-between flex-wrap">
          <div>
            <h1>OP {{ order.production_id }} / {{ order.id }}</h1>
          </div>
          <div class="">
  
            <Button class="ml-2" label="REPORTAR INCIDENCIA" @click="showReportMotiveModal = true" />
          </div>
        </div>

        <div class="">
          <div class="formgrid grid">

            <div class="field col">
              <label for="detail">PIEZA</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.piece }}
              </span>
            </div>

            <div class="field col">
              <label for="detail">CANTIDAD</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.quantity }}
              </span>
            </div>
          </div>
        </div>

        <div class="">
          <div class="formgrid grid">
            <div class="field col">
              <label for="detail">MATERIAL</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ giveMeName(order.material, 'material') }}
              </span>
            </div>
            <div class="field col">
              <label for="detail">MAQUINA</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ giveMeName(order.machine_id, 'machine') }}
              </span>
            </div>

            <div class="field col">
              <label for="detail">TRATAMIENTO</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ giveMeName(order.tratamiento, 'tratamiento') }}
              </span>
            </div>
            <div class="field col">
              <label for="detail">CLIENTE</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ giveMeName(order.client_id, 'client') }}
              </span>
            </div>

          </div>
        </div>
        <div class="">
          <div class="formgrid grid">
            <div class="field col">
              <label for="detail">PLANOS</label>

              <div>
                <div v-for="(plan, index) in order.plans" :key="index">
                  <Button
                    :label="plan.name"
                    icon="pi pi-file-pdf"
                    iconPos="left"
                    class="btn plan_button"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

         <div>
        <div class="mt-3">
          <div class="col-12">
            <OtiClock
              :order="order"
            />
          </div>
        </div>
        </div>
     </div>
</template>
<script>
import CrudService from "./../../services/crud.service.js";
import OtiActions from "../OtiActions.vue"; 
import OtiClock from "./../OtiClock.vue";
export default {
    props: ['order'],
    components: {
      OtiActions,
      OtiClock,
    },
    data() {
        return {
            showReportMotiveModal: false,
            materials: [],
            machines: [],
            tratamientos: [],
            clients: [],
            plans: [],
            motivo: '',
            reportOptions: [
                {name: 'Gerencia', id: 1},
                {name: 'Calidad', id: 3},
                {name: 'Ingeniería', id: 5},

            ],
        }
    },
    created() {
        if (!this.loggedIn) {
            this.$router.push('/');
        }
        this.getMaterials();
        this.getMachines();
        this.getTratamientos();
        this.getClients();
        this.getPlans();
        this.getIncidencesTypes();
    },
    methods: {
        setReport(id) {
            if (! this.motivo) {
                this.motivo = 'Ingrese la descripción...';
                return;
            }
            let data = { 'incidencia_tipo_id': id, 'descripcion': this.motivo, 'order': this.order }
            CrudService.createCRUD("incidencia", "incidencia", data).then((data) => {
                this.showReportMotiveModal = false
                this.motivo = '';
                this.$toast.add({
                    severity: "success",
                    summary: "Éxito!",
                    detail: "Creado correctamente.",
                    life: 3000,
                });
            });
        },
        getMaterials() {
            CrudService.getCRUD('material', 'material').then(response => {
                this.materials = response;
            });
        },
        getIncidencesTypes() {
            CrudService.getCRUD('tipos-incidencias', 'tipos-incidencias').then(response => {
                this.reportOptions = response;
            });
        },
        getMachines() {
            CrudService.getCRUD('maquinaria', 'maquinaria').then(response => {
                this.machines = response;
            });
        },
        getTratamientos() {
            CrudService.getCRUD('tratamiento', 'tratamiento').then(response => {
                 this.tratamientos = response;
            });
        },
        getClients() {
            CrudService.getCRUD('cliente', 'cliente').then(response => {
                this.clients = response;
            });
        },
        getPlans() {
            // CrudService.getCRUD('planes', 'planes', this.order.id).then(response => {
            //     this.plans = response;
            // });
        },
        giveMeName(id, type) {
            let name = '';
            if (type === 'material') {
                this.materials.forEach(material => {
                    if (material.id == id) {
                        name = material.name;
                    }
                });
            } else if (type === 'machine') {
                this.machines.forEach(machine => {
                    if (machine.id == id) {
                        name = machine.name;
                    }
                });
            } else if (type === 'tratamiento') {
                this.tratamientos.forEach(tratamiento => {
                    if (tratamiento.id == id) {
                        name = tratamiento.name;
                    }
                });
            } else if (type === 'client') {
                this.clients.forEach(client => {                    
                    if (client.id == id) {
                        name = client.name;
                    }
                });
            }
            return name;
        }
    }
    
}
</script>


<style scoped>
.field > label {
  color: #9a9a9a;
  letter-spacing: 0.64px;
  font-size: 16px;
  text-transform: uppercase;
}

.field > span {
  display: block;
  text-align: left;
  letter-spacing: 0px;
  color: #0c0c0c;
  opacity: 1;
  border: none;
  background: transparent;
  font-size: 20px;
  line-height: 25px;
}

.plan_button {
  background: white;
  border: 1px solid #d8d8d8;
  font-size: 16px;
  line-height: 19px;
  color: #9a9a9a;
  width: 483px;
  height: 56px;
  text-align: left;
}

.plan_button:hover {
  border: 1px solid #d8d8d8;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  width: 483px;
  height: 56px;
}

.reportButton {
      font-size: 1.4rem;
    text-transform: uppercase;
    margin: 1%;
}

.incidencia {
    height: 3rem;
}
</style>