    <template>
  <div>
    <div class="grid">
      <div class="col-3" >
      <div class="card" style="border-bottom: 5px solid #1C95C2">
        <h1>{{ orders.filter((order) => order.status == 1).length }}</h1>
        <h3>EN PROCESO</h3>
      </div>
    </div>
    <div class="col-3" >
      <div class="card" style="border-bottom: 5px solid #9A9A9A">
        <h1>{{ orders.filter((order) => order.status == 0).length }}</h1>
        <h3>SIN INICIAR</h3>
      </div>
    </div>
    <div class="col-3" >
      <div class="card" style="border-bottom: 5px solid #EEC216">        
        <h1>{{ orders.filter((order) => order.status == 2).length }}</h1>
        <h3>EN PAUSA</h3>  
      </div>
    </div>
    <div class="col-3" >
      <div class="card" style="border-bottom: 5px solid #7E57C2">        
        <h1>{{ orders.filter((order) => order.status == 3).length }}</h1>
        <h3>EN CALIDAD</h3>  
      </div>
    </div>

  </div>

  
    <TabView ref="tabview1">
      <TabPanel header="EN PROCESO">
          <div class="grid"> <OtiForOp v-for="order in orders.filter((order) => order.status == 1)" :key="order.id" :order="order" /> </div>
      </TabPanel>
      <TabPanel header="SIN INICIAR">
          <div class="grid"> <OtiForOp v-for="order in orders.filter((order) => order.status == 0)" :key="order.id" :order="order" /> </div>
      </TabPanel>

      <TabPanel header="EN PAUSA">
          <div class="grid"> <OtiForOp v-for="order in orders.filter((order) => order.status == 2)" :key="order.id" :order="order" /> </div>
      </TabPanel>
      <TabPanel header="EN CALIDAD">
          <div class="grid"> <OtiForOp v-for="order in orders.filter((order) => order.status == 3)" :key="order.id" :order="order" /> </div>
      </TabPanel>
                  
    </TabView>
  

<!-- 
     
- <div class="">
    TABLA DE CONTROL
</div>

        <div class="mt-3">
          <div class="col-12">
            <OtiClock
              :order="order"
              :opGroup="opGroup"
            />
          </div>
        </div>
				<p>{{ order }}</p> 
      </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import OtiClock from "./../OtiClock.vue";
import CrudService from "./../../services/crud.service.js" 
import OtiForOp from "./OtiForOp.vue";
export default {
  props: ['opId', 'opGroup'],
  components: {
    OtiClock,
    OtiForOp
  },
  data() {
    return {
      orders: [],
    };
  },
  created() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      
      CrudService.getCRUD("orden", "otibyuser/"+this.opId).then((data) => (this.orders = data));      

    },
  },
};
</script>
